
import Vue from "vue";
import Logo from "@/components/common/Logo.vue";
import { companies } from "@/api";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "SignupPrivacyPolicy",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/Vertical-Gas-Station-quarter-container.jpg"),
      terms: "",
    };
  },
  computed: {},
  created() {
    this.loadFile();
  },
  methods: {
    async loadFile() {
      try {
        this.terms = (await companies.nlc.getPrivacyPolicy()).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
